import {Form, Formik} from "formik";
import React, {useContext} from "react";
import {useNavigate} from "react-router-dom";

import {Card, Notification, toast} from "../../../../../../../components/ui";
import CancelButton from "../../../../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../../../../components/ui/Buttons/_components/SaveButton";
import {CompanyContext} from "../../Company";
import FormDescription from "../../../../../../../components/RefactoredForms/FormDescription";
import EmployeeCard from "./_components/EmployeeCard";
import EmployeeSearch from "./_components/EmployeeSearch";
import EmployeeCreate from "./_components/EmployeeCreate";
import employmentsService from "../../../../../../../services/employmentsService";
import EmploymentForm from "./_components/EmploymentForm";
import companyEmploymentFormConfig from "./_components/companyEmploymentFormConfig";

const CompanyEmployeesCreate = () => {
    const navigate = useNavigate();
    const {company} = useContext(CompanyContext);
    const [selectedEmployee, setSelectedEmployee] = React.useState(null);

    if (selectedEmployee?.id === undefined) {
        if (selectedEmployee?.fiscal_code !== undefined) {
            return <EmployeeCreate
                fiscal_code={selectedEmployee?.fiscal_code}
                onCancel={() => setSelectedEmployee(null)}
                onEmployeeFound={(employee) => {
                    setSelectedEmployee(employee);
                }}/>
        }

        return <>
            <EmployeeSearch onEmployeeFound={(employee) => {
                setSelectedEmployee(employee);
            }}/>
        </>
    }

    return (<div className="space-y-2">
            <EmployeeCard employee={selectedEmployee}/>
            <Card>
                <Formik
                    validationSchema={companyEmploymentFormConfig.validationSchema}
                    initialValues={companyEmploymentFormConfig.initialValues}
                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(true);
                        employmentsService
                            .store({
                                ...values,
                                domicile_address: !values.domicile_address_like_residence ? values.domicile_address : null,
                                status: values.status.value,
                                contract_type: values.contract_type.value,
                                hire_date: values.hire_date ? values.hire_date.toISOString() : null,
                                contract_end_date: values.contract_end_date ? values.contract_end_date.toISOString() : null,
                                termination_date: values.termination_date ? values.termination_date.toISOString() : null,
                                company_id: company?.id,
                                employee_id: selectedEmployee.id
                            })
                            .then(res => {
                                toast.push(<Notification title='Impiegato inserito con successo'
                                                         type='success'/>);
                                navigate("../" + res.uuid);
                            })
                            .catch(err => toast.push(<Notification
                                title={err?.response?.data?.message}
                                type='danger'/>))
                            .finally(() => setSubmitting(false));
                        setSubmitting(false);
                    }}
                >
                    {({isValid, isSubmitting, resetForm}) => (<Form>
                        <div className='flex flex-col gap-4'>
                            <FormDescription title='Inserisci nuovo impiego'/>
                            <EmploymentForm/>
                            <div className='gap-4 flex justify-end'>
                                <CancelButton label={"Annulla"}
                                              handleClick={() => navigate("../")}/>
                                <CancelButton handleClick={() => resetForm()}/>
                                <SaveButton loading={isSubmitting} disabled={!isValid}/>
                            </div>
                        </div>
                    </Form>)}
                </Formik>
            </Card>
        </div>


    );
};

export default CompanyEmployeesCreate;
