import {useRequest} from "ahooks";
import React, {useContext} from "react";
import {Link, useNavigate} from "react-router-dom";

import CreateButton from "../../../../../../../components/ui/Buttons/_components/CreateButton";
import LaravelTable from "../../../../../../../components/ui/LaravelTable/LaravelTable";
import PageHeader from "../../../../../../../components/ui/Page/PageHeader";
import {CompanyContext} from "../../Company";
import DateFormatDisplay from "../../../../../../../components/ui/Formatter/DateFormatDisplay";
import employmentsService from "../../../../../../../services/employmentsService";
import {Card} from "../../../../../../../components/ui";
import StateDisplay from "../../../../../../../components/ui/State/StateDisplay";


const tableCols = [
    {
        header: "Impiegato",
        accessorKey: "employee",
        cell: ({getValue, row: {original}}) =>
            <Link to={original.uuid} className='font-bold'>
                {getValue().last_name} {getValue().first_name}
            </Link>
    },
    {
        header: "Stato",
        accessorKey: "status",
        cell: ({getValue}) => <StateDisplay state={getValue()}/>,
    },
    {
        header: "Data di assunzione",
        accessorKey: "hire_date",
        cell: ({getValue}) => <DateFormatDisplay value={getValue()}/>
    },
    {
        header: "Data di cessazione",
        accessorKey: "termination_date",
        cell: ({getValue}) => <DateFormatDisplay value={getValue()}/>
    },

];

const CompanyEmployeesIndex = () => {
    const {company} = useContext(CompanyContext);
    const navigate = useNavigate();

    const {
        data: employments,
        run,
        loading,
    } = useRequest(employmentsService.fetchAll, {
        manual: true,
        defaultParams: [company?.id],
    });

    const handleRun = params => {
        if (company?.id) {
            run({...params, company_id: company.id});
        }
    };

    return (
        <Card>
            <PageHeader loading={loading}
                        toolbar={<CreateButton handleClick={() => navigate("create")}/>}/>
            <LaravelTable columns={tableCols} records={employments} loading={loading}
                          onParamsChange={handleRun} searchable sortable/>
        </Card>
    );
};

export default CompanyEmployeesIndex;
