import api from "../configs/api.config";

const EMPLOYMENTS_BASE_URL = "/employments";

const fetchAll = params => api.get(EMPLOYMENTS_BASE_URL, {params}).then(res => res.data);
const fetch = id => api.get(`${EMPLOYMENTS_BASE_URL}/${id}`).then(res => res.data.data);
const store = params => api.post(`${EMPLOYMENTS_BASE_URL}`, params).then(res => res.data.data);
const update = (id, params) => api.put(`${EMPLOYMENTS_BASE_URL}/${id}`, params).then(res => res.data.data);

const employmentsService = {
    fetchAll,
    fetch,
    store,
    update,
};

export default employmentsService;
