import {Form, Formik} from "formik";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {Card, Notification, toast} from "../../../../../../../components/ui";
import CancelButton from "../../../../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../../../../components/ui/Buttons/_components/SaveButton";
import {CompanyContext} from "../../Company";
import FormDescription from "../../../../../../../components/RefactoredForms/FormDescription";
import EmployeeCard from "./_components/EmployeeCard";
import employmentsService from "../../../../../../../services/employmentsService";
import EmploymentForm from "./_components/EmploymentForm";
import companyEmploymentFormConfig from "./_components/companyEmploymentFormConfig";
import {useRequest} from "ahooks";


const CompanyEmployeesShow = () => {
    const {company} = useContext(CompanyContext);
    const {employment_id} = useParams();
    const navigate = useNavigate();

    const {
        data: employment,
        loading,
        mutate
    } = useRequest(employmentsService.fetch, {defaultParams: [employment_id]});


    const [initialValues, setInitialValues] = useState(companyEmploymentFormConfig.initialValues);

    useEffect(() => {
        if (employment) {
            setInitialValues(companyEmploymentFormConfig.setInitialValues(employment));
        }
    }, [employment]);


    // const handleDelete = () => {
    //     attachmentService
    //         .companyDestroy(company.uuid, attachment.uuid)
    //         .then(() => {
    //             toast.push(<Notification title='Allegato eliminato con successo' type='success' />);
    //             navigate("../");
    //         })
    //         .catch(err => toast.push(<Notification title={err?.response?.data?.message} type='danger' />));
    // };


    if (!employment)
        return null;

    return (<div className="space-y-2">
            <EmployeeCard employee={employment.employee}/>
            <Card>
                <Formik
                    validationSchema={companyEmploymentFormConfig.validationSchema}
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={(values, {setSubmitting}) => {
                        setSubmitting(true);
                        employmentsService
                            .update(employment.uuid, {
                                ...values,
                                domicile_address: !values.domicile_address_like_residence ? values.domicile_address : null,
                                status: values.status.value,
                                contract_type: values.contract_type.value,
                                hire_date: values.hire_date ? values.hire_date.toISOString() : null,
                                contract_end_date: values.contract_end_date ? values.contract_end_date.toISOString() : null,
                                termination_date: values.termination_date ? values.termination_date.toISOString() : null,
                            })
                            .then(res => toast.push(<Notification
                                title='Impiegato aggiornato con successo' type='success'/>))
                            .catch(err => toast.push(<Notification
                                title={err?.response?.data?.message}
                                type='danger'/>))
                            .finally(() => setSubmitting(false));
                        setSubmitting(false);
                    }}
                >
                    {({isValid, isSubmitting, resetForm}) => (<Form>
                        <div className='flex flex-col gap-4'>
                            <FormDescription title='Modifica impiego'/>
                            <EmploymentForm/>
                            <div className='gap-4 flex justify-end'>
                                <CancelButton label={"Annulla"}
                                              handleClick={() => navigate("../")}/>
                                <CancelButton handleClick={() => resetForm()}/>
                                <SaveButton loading={isSubmitting} disabled={!isValid}/>
                            </div>
                        </div>
                    </Form>)}
                </Formik>
            </Card>
        </div>


    );
};

export default CompanyEmployeesShow;
