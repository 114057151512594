import React, {useContext} from "react";
import {CompanyContext} from "../../Company";
import BaseCompanyInfo from "../../../../../../../components/ui/Company/BaseCompanyInfo";
import BaseCompanyContacts from "../../../../../../../components/ui/Company/BaseCompanyContacts";
import BaseCompanyEmails from "../../../../../../../components/ui/Company/BaseCompanyEmails";
import CompanyPublicLinks from "../../../../../../../components/ui/Company/CompanyPublicLinks";
import {Card, Menu, MenuItem} from "../../../../../../../components/ui";

const CompanyDashboardTab = () => {
    const {company, loading} = useContext(CompanyContext);

    return (
        <>
            <div className='flex flex-row flex-wrap justify-center gap-4 w-full'>
                <BaseCompanyContacts company={company} loading={loading}/>
                <BaseCompanyEmails company={company} loading={loading}/>
                <CompanyPublicLinks company={company} loading={loading}/>
            </div>
        </>
    );
};

export default CompanyDashboardTab;
