import {useRequest} from "ahooks";
import React, {useContext} from "react";
import {Card, Tag} from "../../../../../../../components/ui";
import Accordion from "../../../../../../../components/ui/Accordion/Accordion";
import AccordionItem from "../../../../../../../components/ui/Accordion/AccordionItem";
import UserDisplay from "../../../../../../../components/ui/Users/UserDisplay";
import UserInviteState from "../../../../../../../components/ui/Users/UserInviteState";
import UserState from "../../../../../../../components/ui/Users/UserState";
import usersService from "../../../../../../../services/usersService";
import {CompanyContext} from "../../Company";
import CompanyUserCreate from "./CompanyUserCreate";
import CompanyUserShow from "./CompanyUserShow";
import CompanyUserDropdown from "./_components/CompanyUserDropdown";
import listService from "../../../../../../../services/listService";

const CompanyUsersTab = () => {
    const {company, roles} = useContext(CompanyContext);

    const {
        data: users = [],
        mutate,
        loading,
        run,
    } = useRequest(usersService.companyFetchAll, {
        defaultParams: [company.uuid],
        ready: company?.uuid,
    });

    const {data: documentTypes} = useRequest(listService.publicDocumentTypes);

    const handleUpdate = user => mutate(users.map(u => (u.id === user?.id ? user : u)));

    return (
        <Card>
            {!loading && (
                <Accordion defaultValue={undefined}>
                    {users.map(user => (
                        <AccordionItem
                            key={user.id}
                            value={user.id}
                            title={
                                <div className='flex flex-grow items-center justify-between gap-4'>
                                    <div className='flex items-center justify-start gap-4'>
                                        <UserDisplay user={user}>{user.email}</UserDisplay>
                                        <Tag>{user?.role?.name}</Tag>
                                    </div>
                                    <div className='flex items-center justify-end gap-4'>
                                        <UserState user={user} field='company_user.active'/>
                                        <UserInviteState value={user.welcome_valid_until}/>
                                        <CompanyUserDropdown
                                            user={user}
                                            onToggleActivation={handleUpdate}
                                            onDelete={() => mutate(users.filter(u => u.id !== user.id))}
                                            onReInvite={handleUpdate}
                                        />
                                    </div>
                                </div>
                            }
                        >
                            <CompanyUserShow user={user} documentTypes={documentTypes ?? []}/>
                        </AccordionItem>
                    ))}

                    <AccordionItem value='create' title='Aggiungi nuovo utente'>
                        <CompanyUserCreate onCreate={user => mutate([...users, user])} roles={roles}
                                           documentTypes={documentTypes ?? []}/>
                    </AccordionItem>
                </Accordion>
            )}
        </Card>
    );
};

export default CompanyUsersTab;
