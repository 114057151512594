import {Form, Formik} from "formik";
import React from "react";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";

import FormCheckbox from "../../../../components/RefactoredForms/FormCheckbox";
import FormDescription from "../../../../components/RefactoredForms/FormDescription";
import FormGroup from "../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../components/RefactoredForms/FormInput";
import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import {Card, Notification, toast} from "../../../../components/ui";
import CancelButton from "../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../components/ui/Buttons/_components/SaveButton";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import documentCategoryService from "../../../../services/documentCategoryService";

const DocumentCategoryCreate = () => {
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        name: Yup.string().max(255).required(),
        description: Yup.string().max(800).notRequired(),
        is_split_by_year: Yup.bool().notRequired(),
        is_split_by_month: Yup.bool().notRequired(),
        is_for_company: Yup.bool().notRequired(),
        is_for_employee: Yup.bool().notRequired(),
    });

    const initialValues = {
        name: "",
        description: "",
        is_split_by_year: false,
        is_split_by_month: false,
        is_for_company: false,
        is_for_employee: false,
    };

    return (
        <PageContainer>
            <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={(values, {setSubmitting}) => {
                    documentCategoryService
                        .store(values)
                        .then(res => {
                            toast.push(<Notification title='Categoria creata con successo'
                                                     type='success'/>);
                            navigate("../" + res.uuid);
                        })
                        .catch(err => toast.push(<Notification title={err?.response?.data?.message}
                                                               type='danger'/>))
                        .finally(() => setSubmitting(false));
                }}
            >
                {({isValid, isSubmitting, resetForm}) => (
                    <Form>
                        <Card
                            header={
                                <PageHeader
                                    title='Nuova categoria documento'
                                    description='Crea una nuova categoria documento.'
                                    loading={false}
                                    toolbar={
                                        <>
                                            <CancelButton label={"Annulla"}
                                                          handleClick={() => navigate("../")}/>
                                            <SaveButton loading={isSubmitting} disabled={!isValid}/>
                                        </>
                                    }
                                />
                            }
                        >
                            <div className='flex flex-col gap-4'>
                                <div className='grid lg:grid-cols-2 xl:grid-cols-3 gap-4'>
                                    <FormGroup label='Nome' name='name' required>
                                        <FormInput name='name'/>
                                    </FormGroup>
                                    <FormGroup label='Descrizione' name='description' required>
                                        <FormInput name='description'/>
                                    </FormGroup>
                                </div>

                                <FormDescription title='Divisone annuale'
                                                 desc='Definisce se la categoria documento deve essere suddivisa per anno'/>
                                <FormGroup label='' name='is_split_by_year' required>
                                    <FormCheckbox name={"is_split_by_year"}
                                                  label={"Abilita divisione annuale"}/>
                                </FormGroup>

                                <FormDescription
                                    title='Divisone mensile'
                                    desc='Definisce se la categoria documento deve essere suddivisa in mensilità'
                                />
                                <FormGroup label='' name='is_split_by_month' required>
                                    <FormCheckbox name={"is_split_by_month"}
                                                  label={"Abilita divisione mensile"}/>
                                </FormGroup>


                                <FormDescription title='Settore'
                                                 desc='Definisci per quale sezione è disponibile la categoria'/>
                                <FormGroup label='' name='is_for_company' required>
                                    <FormCheckbox name="is_for_company"
                                                  label="Categoria abilitata per le aziende"/>
                                </FormGroup>
                                <FormGroup label='' name='is_for_employee' required>
                                    <FormCheckbox name="is_for_employee"
                                                  label="Categoria abilitata per i dipendenti"/>
                                </FormGroup>
                            </div>
                        </Card>
                    </Form>
                )}
            </Formik>
        </PageContainer>
    );
};

export default DocumentCategoryCreate;
