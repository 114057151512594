import {useRequest} from "ahooks";
import {Form, Formik} from "formik";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import * as Yup from "yup";

import FormCheckbox from "../../../../../../../components/RefactoredForms/FormCheckbox";
import FormDescription from "../../../../../../../components/RefactoredForms/FormDescription";
import FormGroup from "../../../../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../../../../components/RefactoredForms/FormInput";
import FormSelect from "../../../../../../../components/RefactoredForms/FormSelect";
import {Card, Notification, toast} from "../../../../../../../components/ui";
import CancelButton from "../../../../../../../components/ui/Buttons/_components/CancelButton";
import DestroyButton from "../../../../../../../components/ui/Buttons/_components/DestroyButton";
import SaveButton from "../../../../../../../components/ui/Buttons/_components/SaveButton";
import companyGroupService from "../../../../../../../services/companyGroupService";
import companyService from "../../../../../../../services/companyService";
import {companyFormConfig} from "../../../_components/companyFormConfig";
import {CompanyContext} from "../../Company";

const CompanyInfoTab = () => {
    const {company, setCompany, loading} = useContext(CompanyContext);
    const [initialValues, setInitialValues] = useState(companyFormConfig.companyInitialValues);
    const navigate = useNavigate();

    const {data: companyGroups} = useRequest(companyGroupService.noPaginate);

    useEffect(() => {
        if (!loading) {
            const {company_data, ...companyProps} = company;
            setInitialValues({
                ...initialValues,
                ...companyProps,
                groups: company.groups.map(group => {
                    return {
                        value: group.id,
                        label: group.name,
                    };
                }),
            });
        }
    }, [company]);

    const handleDelete = () =>
        companyService
            .destroy(company?.uuid)
            .then(res => {
                toast.push(<Notification title='Azienda eliminata con successo' type='success'/>);
                navigate("/admin/companies");
            })
            .catch(err => toast.push(<Notification title={err.response.data.message}
                                                   type='danger'/>));

    return (
        <Card>
            <Formik
                validationSchema={Yup.object().shape(companyFormConfig.companyValidationSchema)}
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting}) => {
                    companyService
                        .update(company.uuid, {
                            ...values,
                            groups: values.groups.map(group => group.value)
                        })
                        .then(res => {
                            toast.push(<Notification title='Azienda aggiornata con successo'
                                                     type='success'/>);
                            setCompany(res);
                        })
                        .catch(err => toast.push(<Notification title={err.response.data.message}
                                                               type='danger'/>))
                        .finally(() => setSubmitting(false));
                }}
            >
                {({isValid, isSubmitting, resetForm, dirty, setFieldValue, values}) => (
                    <Form>
                        <div className='flex flex-col gap-4'>
                            <FormDescription title='Informazioni generali'/>

                            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                                <FormGroup label={"Codice"} name={"code"}>
                                    <FormInput name={"code"} disabled={!!company?.code}/>
                                </FormGroup>
                                <FormGroup label={"Ragione Sociale"} name={"business_name"}
                                           required>
                                    <FormInput name={"business_name"}/>
                                </FormGroup>
                                <FormGroup label={"Partita Iva"} name={"vat_number"}>
                                    <FormInput name={"vat_number"}/>
                                </FormGroup>
                                <FormGroup label={"Codice Fiscale"} name={"fiscal_code"} required>
                                    <FormInput name={"fiscal_code"}/>
                                </FormGroup>
                            </div>

                            <FormDescription title='Gruppi'/>

                            <FormGroup label={"Gruppi aziende"} name={"groups"}>
                                <FormSelect
                                    name='groups'
                                    isMulti
                                    isClearable
                                    options={companyGroups}
                                    value={values.groups}
                                    onChange={values => setFieldValue("groups", values)}
                                />
                            </FormGroup>

                            <FormDescription title='Permessi'/>

                            <FormCheckbox name={"can_manage_other_companies"}
                                          label={"Possibilità di gestire altre aziende"}/>

                            <div className='gap-4 flex justify-end'>
                                <CancelButton disabled={!isValid || isSubmitting}
                                              handleClick={() => resetForm()}/>
                                <SaveButton loading={isSubmitting}
                                            disabled={!isValid || isSubmitting || !dirty}/>
                                <DestroyButton
                                    message={`Sei sicuro di voler eliminare ${company?.business_name}?`}
                                    loading={loading}
                                    handleClick={() => handleDelete()}
                                />
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </Card>
    );
};

export default CompanyInfoTab;
