import {cn} from "../../../utils/classHelpers";
import React from "react";

import { useConfig } from "../ConfigProvider";
import { MenuContextProvider } from "./context/menuContext";

const Menu = React.forwardRef((props, ref) => {
    const { children, className, defaultActiveKeys, defaultExpandedKeys, menuItemHeight, onSelect, sideCollapsed, variant, ...rest } = props;

    const menuDefaultClass = "menu";

    const { themeColor, primaryColorLevel } = useConfig();

    const menuColor = () => `bg-${themeColor}-${primaryColorLevel} ${menuDefaultClass}-${variant}`;

    const menuClass = cn(menuDefaultClass, menuColor(), className);

    return (
        <nav ref={ref} className={menuClass} {...rest}>
            <MenuContextProvider
                value={{
                    onSelect,
                    menuItemHeight,
                    variant,
                    sideCollapsed,
                    defaultExpandedKeys,
                    defaultActiveKeys,
                }}
            >
                {children}
            </MenuContextProvider>
        </nav>
    );
});

Menu.defaultProps = {
    menuItemHeight: 35,
};

export default Menu;
