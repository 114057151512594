import React from 'react';
import FormDescription from "../../../../../../../../components/RefactoredForms/FormDescription";
import {Card, Notification, toast} from "../../../../../../../../components/ui";
import {Form, Formik} from "formik";
import FormGroup from "../../../../../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../../../../../components/RefactoredForms/FormInput";
import SaveButton from "../../../../../../../../components/ui/Buttons/_components/SaveButton";
import employeesService from "../../../../../../../../services/employeesService";

const EmployeeSearch = ({onEmployeeFound}) => {

    const handleFindEmployee = (fiscalCode) => {
        return employeesService.findByFiscalCode(fiscalCode).then(res => {
            onEmployeeFound(res);

        }).catch(err => {
            toast.push(<Notification title="Nessun dipendente trovato"
                                     children="Nessun dipendente con questo codice fiscale è stato trovato, crealo ora"
                                     type='danger'/>)

            onEmployeeFound({fiscal_code: fiscalCode});
        });
    }


    return (
        <Card>
            <Formik
                initialValues={{fiscal_code: ''}}
                onSubmit={async (values, {setSubmitting, resetForm}) => {
                    setSubmitting(true);
                    await handleFindEmployee(values.fiscal_code);
                    setSubmitting(false);
                    resetForm();
                }}>
                {(f) =>
                    <Form>
                        <div className='flex flex-col gap-4'>
                            <FormDescription title='Ricerca dipendente'/>
                            <FormGroup label='Codice Fiscale' required>
                                <FormInput name='fiscal_code'/>
                            </FormGroup>
                            <div className='gap-4 flex justify-end'>
                                <SaveButton label="Cerca" loading={f.isSubmitting}/>
                            </div>
                        </div>
                    </Form>
                }
            </Formik>
        </Card>
    );
};

export default EmployeeSearch;
