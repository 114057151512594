import {useRequest} from "ahooks";
import {Form, Formik} from "formik";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";

import FormCheckbox from "../../../../components/RefactoredForms/FormCheckbox";
import FormDescription from "../../../../components/RefactoredForms/FormDescription";
import FormGroup from "../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../components/RefactoredForms/FormInput";
import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import {Card, Notification, toast} from "../../../../components/ui";
import CancelButton from "../../../../components/ui/Buttons/_components/CancelButton";
import DestroyButton from "../../../../components/ui/Buttons/_components/DestroyButton";
import SaveButton from "../../../../components/ui/Buttons/_components/SaveButton";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import documentCategoryService from "../../../../services/documentCategoryService";
import DocumentCategoryService from "../../../../services/documentCategoryService";

const DocumentCategoryShow = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const {
        data: documentCategory,
        loading,
        mutate
    } = useRequest(documentCategoryService.fetch, {defaultParams: [id]});

    const validationSchema = Yup.object().shape({
        name: Yup.string().max(255).required(),
        description: Yup.string().max(800).notRequired(),
        is_split_by_year: Yup.bool().notRequired(),
        is_split_by_month: Yup.bool().notRequired(),
        is_for_company: Yup.bool().notRequired(),
        is_for_employee: Yup.bool().notRequired(),
    });

    const initialValues = {
        name: documentCategory?.name,
        description: documentCategory?.description,
        is_split_by_year: documentCategory?.is_split_by_year,
        is_split_by_month: documentCategory?.is_split_by_month,
        is_for_company: documentCategory?.is_for_company,
        is_for_employee: documentCategory?.is_for_employee,
    };

    const handleDelete = () => {
        DocumentCategoryService.destroy(documentCategory.uuid)
            .then(res => {
                toast.push(<Notification title='Categoria documento eliminata con successo'
                                         type='success'/>);
                navigate("../");
            })
            .catch(err => toast.push(<Notification title={err?.response?.data?.message}
                                                   type='danger'/>));
    };

    return (
        <PageContainer>
            <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={(values, {setSubmitting}) => {
                    documentCategoryService
                        .update(documentCategory.uuid, values)
                        .then(res => {
                            toast.push(<Notification title='Categoria aggiornata con successo'
                                                     type='success'/>);
                            mutate(res);
                        })
                        .catch(err => toast.push(<Notification title={err?.response?.data?.message}
                                                               type='danger'/>))
                        .finally(() => setSubmitting(false));
                }}
            >
                {({isValid, isSubmitting, resetForm, values}) => (
                    <Form>
                        <Card
                            header={
                                <PageHeader
                                    title={`Modifica ${documentCategory?.name}`}
                                    loading={false}
                                    toolbar={
                                        <>
                                            <CancelButton label={"Annulla"}
                                                          handleClick={() => navigate("../")}/>
                                            <SaveButton loading={isSubmitting} disabled={!isValid}/>
                                            <DestroyButton
                                                message={`Sei sicuro di voler eliminare la categoria documento ${documentCategory?.name}?`}
                                                handleClick={handleDelete}
                                            />
                                        </>
                                    }
                                />
                            }
                        >
                            <div className='flex flex-col gap-4'>
                                <div className='grid lg:grid-cols-2 xl:grid-cols-3 gap-4'>
                                    <FormGroup label='Nome' name='name' required>
                                        <FormInput name='name'/>
                                    </FormGroup>
                                    <FormGroup label='Descrizione' name='description' required>
                                        <FormInput name='description'/>
                                    </FormGroup>
                                </div>

                                <FormDescription title='Divisone annuale'
                                                 desc='Definisce se la categoria documento deve essere suddivisa per anno'/>
                                <FormGroup label='' name='is_split_by_year' required>
                                    <FormCheckbox name={"is_split_by_year"}
                                                  label={"Abilita divisione annuale"}/>
                                </FormGroup>

                                <FormDescription
                                    title='Divisone mensile'
                                    desc='Definisce se la categoria documento deve essere suddivisa in mensilità'
                                />
                                <FormGroup label='' name='is_split_by_month' required>
                                    <FormCheckbox name={"is_split_by_month"}
                                                  label={"Abilita divisione mensile"}/>
                                </FormGroup>


                                <FormDescription title='Settore'
                                                 desc='Definisci per quale sezione è disponibile la categoria'/>
                                <FormGroup label='' name='is_for_company' required>
                                    <FormCheckbox name="is_for_company"
                                                  label="Categoria abilitata per le aziende"/>
                                </FormGroup>
                                <FormGroup label='' name='is_for_employee' required>
                                    <FormCheckbox name="is_for_employee"
                                                  label="Categoria abilitata per i dipendenti"/>
                                </FormGroup>
                            </div>
                        </Card>
                    </Form>
                )}
            </Formik>
        </PageContainer>
    );
};

export default DocumentCategoryShow;
