import React from 'react';
import {Card} from "../../../../../../../../components/ui";
import PageHeader from "../../../../../../../../components/ui/Page/PageHeader";
import {HiBriefcase} from "@react-icons/all-files/hi/HiBriefcase";
import {HiUser} from "react-icons/hi";

const EmployeeCard = ({employee}) => {
    return (

        <Card className='flex items-center justify-start flex-1'>
            <PageHeader
                title={employee?.first_name + ' ' + employee?.last_name}
                description={employee?.email}
                icon={<HiUser className='text-neutral-300' size={30}/>}
            />
        </Card>
    );
};

export default EmployeeCard;
