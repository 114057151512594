import EmployeeDocumentTypeCreate
    from "../../../../views/protected/admin/employeeDocumentTypes/EmployeeDocumentTypeCreate";
import EmployeeDocumentTypeShow
    from "../../../../views/protected/admin/employeeDocumentTypes/EmployeeDocumentTypeShow";
import EmployeeDocumentTypes
    from "../../../../views/protected/admin/employeeDocumentTypes/EmployeeDocumentTypes";
import EmployeeDocumentTypesIndex
    from "../../../../views/protected/admin/employeeDocumentTypes/EmployeeDocumentTypesIndex";

const employeeDocumentTypesRoutes = {
    element: <EmployeeDocumentTypes/>,
    children: [
        {
            path: "",
            element: <EmployeeDocumentTypesIndex/>,
        },
        {
            path: "create",
            element: <EmployeeDocumentTypeCreate/>,
        },
        {
            path: ":id",
            element: <EmployeeDocumentTypeShow/>,
        },
    ],
};

export default employeeDocumentTypesRoutes;
