import api from "../configs/api.config";

const BASE_LIST_URL = "lists";
const users = () => api.get(`${BASE_LIST_URL}/users`).then(res => res.data);
const permissions = () => api.get(`${BASE_LIST_URL}/permissions`).then(res => res.data);
const roles = () => api.get(`${BASE_LIST_URL}/roles`).then(res => res.data);
const documentTypes = () => api.get(`${BASE_LIST_URL}/document-types`).then(res => res.data);
const employeeDocumentTypes = (params) => api.get(`${BASE_LIST_URL}/employee-document-types`, {params}).then(res => res.data);
const publicDocumentTypes = () => api.get(`${BASE_LIST_URL}/document-types`, {params: {filter: {only_public: true}}}).then(res => res.data);

const defaultNotificationMessages = () => api.get(`${BASE_LIST_URL}/default-notification-messages`).then(res => res.data);

const listService = {
    users,
    permissions,
    roles,
    documentTypes,
    employeeDocumentTypes,
    publicDocumentTypes,
    defaultNotificationMessages,
};

export default listService;
