import { HEADER_HEIGHT_CLASS } from "constants/theme.constant";
import React from "react";
import {cn} from "../../../../utils/classHelpers";

const Header = ({ container, headerStart, headerEnd }) => {
    return (
        <header className='header shadow dark:shadow-2xl'>
            <div className={cn("header-wrapper", HEADER_HEIGHT_CLASS, container && "container mx-auto")}>
                <div className='header-action header-action-start'>{headerStart}</div>
                <div className='header-action header-action-end'>{headerEnd}</div>
            </div>
        </header>
    );
};

export default Header;
