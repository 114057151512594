import {cn} from "../../../utils/classHelpers";
import React, { useContext } from "react";
import { HiMinus, HiPlus } from "react-icons/hi2";

import { AccordionContext } from "./Accordion";

const AccordionItemHeader = ({ title, value, children }) => {
    const { currentSelected, setCurrentSelected } = useContext(AccordionContext);

    return (
        <div className={`border-b border-gray-200 dark:border-gray-600`}>
            <span
                className={cn(
                    `py-3 px-5 flex items-center font-bold  hover:text-sky-800 hover:bg-neutral-50`,
                    currentSelected === value && `text-sky-800 border-b-2 border-sky-800`
                )}
            >
                <span className='mr-4 cursor-pointer' onClick={() => setCurrentSelected(currentSelected === value ? undefined : value)}>
                    {currentSelected === value ? <HiMinus size={20} /> : <HiPlus size={20} />}
                </span>
                {title}
            </span>
        </div>
    );
};

export default AccordionItemHeader;
