import React, {forwardRef} from "react";

import {useTabs} from "./context";
import {cn} from "../../../utils/classHelpers";

const TabList = forwardRef((props, ref) => {
    const {className, children, ...rest} = props;

    const {variant} = useTabs();

    const tabListClass = cn("tab-list", `tab-list-${variant}`, className);

    return (
        <div role='tablist' className={tabListClass} ref={ref} {...rest}>
            {children}
        </div>
    );
});

export default TabList;
