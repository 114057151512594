import { join } from "lodash";
import React, { useState } from "react";
import { HiDocumentText } from "react-icons/hi";
import { Link } from "react-router-dom";

import DateFormatDisplay from "../../../../../../components/ui/Formatter/DateFormatDisplay";
import UserDisplay from "../../../../../../components/ui/Users/UserDisplay";
import dateHelper from "../../../../../../utils/dateHelper";
import {cn} from "../../../../../../utils/classHelpers";

const MyCompanyUploadRow = ({ index, upload }) => {
    const [loading, setLoading] = useState(false);

    const categoryLink = "/my-company/documents/" + upload?.document_category?.uuid;

    const fullLink =
        join([categoryLink, upload.payload?.year && upload.payload?.year, upload.payload?.month && upload.payload?.month], "/") +
        "?upload=" +
        upload.uuid;

    const yearMonthText = join(
        [upload.payload?.month && dateHelper.getMonth(upload.payload?.month)?.label, upload.payload?.year && upload.payload?.year],
        " "
    );

    return (
        <div className={cn("flex items-center cursor-pointer p-2 gap-2 hover:bg-neutral-100", index % 2 === 0 ? "bg-neutral-50" : "")}>
            <div>
                <HiDocumentText color='#d4d4d4' size={60} />
            </div>
            <div className='flex flex-grow gap-2 justify-between'>
                <div>
                    <h6 className='text-sky-800'>
                        <Link className='hover:text-sky-500' to={fullLink}>
                            {upload?.document_category?.name} {!!yearMonthText && <>{yearMonthText}</>}
                        </Link>
                    </h6>
                    <div className='text-sm flex gap-2'>
                        {upload.documents.length} {upload.documents.length > 1 ? <>documenti caricati</> : <>documento caricato</>}
                    </div>
                </div>
                <UserDisplay user={upload.user} size={20}>
                    <DateFormatDisplay value={upload.payload.auto_publish_at ?? upload.created_at} />
                </UserDisplay>
            </div>
        </div>
    );
};

export default MyCompanyUploadRow;
