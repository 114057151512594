import React, { useContext } from "react";

import { AccordionContext } from "./Accordion";
import AccordionItemHeader from "./AccordionItemHeader";
import {cn} from "../../../utils/classHelpers";

const AccordionItem = ({ title, value, children, alwaysOpen = false }) => {
    const { currentSelected } = useContext(AccordionContext);

    return (
        <div className=''>
            <AccordionItemHeader value={value} title={title} />
            <div className={cn("w-100 px-4 py-6", { hidden: currentSelected !== value && !alwaysOpen })}>{children}</div>
        </div>
    );
};
export default AccordionItem;
