import {Drawer} from "components/ui";
import navigationConfig from "configs/navigation.config/navigation.config";
import React, {lazy, Suspense, useContext, useState} from "react";
import withHeaderItem from "utils/hoc/withHeaderItem";
import useResponsive from "utils/hooks/useResponsive";

import {ApplicationContext} from "../../../../../App";
import NavToggle from "../../../../shared/NavToggle";
import {cn} from "../../../../../utils/classHelpers";

const MenuContent = lazy(() => import("components/layout/ProtectedLayout/_components/MenuContent/MenuContent"));

const MobileNavToggle = withHeaderItem(NavToggle);

const MobileNav = () => {
    const {themeConfig, sideNavCollapse} = useContext(ApplicationContext);
    const {themeColor, primaryColorLevel, navMode, mode} = themeConfig;

    const [isOpen, setIsOpen] = useState(false);

    const openDrawer = () => setIsOpen(true);
    const onDrawerClose = () => setIsOpen(false);

    const currentRouteKey = "";

    const {smaller} = useResponsive();

    const navColor = () => `bg-${themeColor}-${primaryColorLevel} side-nav-${navMode}`;

    return (
        <>
            {smaller.md && (
                <>
                    <div className='text-2xl' onClick={openDrawer}>
                        <MobileNavToggle toggled={isOpen}/>
                    </div>
                    <Drawer
                        title='Menu'
                        isOpen={isOpen}
                        onClose={onDrawerClose}
                        onRequestClose={onDrawerClose}
                        bodyClass={cn(navColor(), "p-0")}
                        width={330}
                        placement='left'
                    >
                        <Suspense fallback={<></>}>
                            {isOpen && (
                                <MenuContent
                                    navMode={navMode}
                                    collapsed={sideNavCollapse}
                                    navigationTree={navigationConfig}
                                    routeKey={currentRouteKey}
                                    onMenuItemClick={onDrawerClose}
                                />
                            )}
                        </Suspense>
                    </Drawer>
                </>
            )}
        </>
    );
};

export default MobileNav;
