import * as Yup from "yup";

const companyInitialValues = {
    business_name: "",
    code: "",
    vat_number: "",
    fiscal_code: "",
    can_manage_other_companies: false,
    groups: [],
};

const companyDataInitialValues = {
    legal_first_name: "",
    legal_last_name: "",
    legal_fiscal_code: "",
    pec: "",
    email: "",
    phone: "",
    referent_name: "",
    inail_code: "",
    building_fund_code: "",
    inail_classification: "",
    legal_office: {
        address: "",
        street_number: "",
        city: "",
        postal_code: "",
        country: "",
        latitude: "",
        longitude: "",
    },
};

const companySettingsInitialValues = {
    is_all_year_active: false,
    is_employee_list_active: false,
    is_employee_detail_active: false,
    is_employee_documents_active: false,
};

const companyValidationSchema = {
    business_name: Yup.string().max(255).nullable().required(),
    code: Yup.number().min(1).max(99999).nullable().notRequired(),
    vat_number: Yup.string().max(15).nullable().notRequired(),
    fiscal_code: Yup.string().max(20).nullable().required(),
    can_manage_other_companies: Yup.boolean().nullable().required(),
    groups: Yup.array().nullable().notRequired(),
};

const companyDataValidationSchema = {
    legal_first_name: Yup.string().max(100).nullable().notRequired(),
    legal_last_name: Yup.string().max(100).nullable().notRequired(),
    legal_fiscal_code: Yup.string().max(20).nullable().notRequired(),
    pec: Yup.string().email().max(100).nullable().notRequired(),
    email: Yup.string().email().max(100).required().notRequired(),
    phone: Yup.string().max(25).nullable().notRequired(),
    referent_name: Yup.string().max(200).nullable().notRequired(),
    inail_code: Yup.string().max(25).nullable().notRequired(),
    building_fund_code: Yup.string().max(255).nullable().notRequired(),
    inail_classification: Yup.string().max(255).nullable().notRequired(),
    legal_office: Yup.object().shape({
        address: Yup.string().max(100).nullable().notRequired(),
        street_number: Yup.string().max(20).nullable().notRequired(),
        city: Yup.string().max(100).nullable().notRequired(),
        postal_code: Yup.string().max(10).nullable().notRequired(),
        country: Yup.string().max(100).nullable().notRequired(),
    }),
};

const companySettingsValidationSchema = {
    is_all_year_active: Yup.boolean().required(),
    is_employee_list_active: Yup.boolean().required(),
    is_employee_detail_active: Yup.boolean().required(),
    is_employee_documents_active: Yup.boolean().required(),
};

export const companyFormConfig = {
    companyInitialValues,
    companyDataInitialValues,
    companyValidationSchema,
    companyDataValidationSchema,

    companySettingsInitialValues,
    companySettingsValidationSchema
};
