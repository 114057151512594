import api from "../configs/api.config";

const DOCUMENT_CATEGORIES_BASE_URL = "/document-categories";

const fetchAll = params => api.get(DOCUMENT_CATEGORIES_BASE_URL, {params}).then(res => res.data);


const fetch = (id, params) => api.get(`${DOCUMENT_CATEGORIES_BASE_URL}/${id}`, params).then(res => res.data.data);
const store = params => api.post(DOCUMENT_CATEGORIES_BASE_URL, params).then(res => res.data.data);
const update = (id, params) => api.put(`${DOCUMENT_CATEGORIES_BASE_URL}/${id}`, params).then(res => res.data.data);
const destroy = id => api.delete(`${DOCUMENT_CATEGORIES_BASE_URL}/${id}`).then(res => res.data);


const forCompany = () =>
    api.get(DOCUMENT_CATEGORIES_BASE_URL, {
        params: {
            filter: {
                is_for_company: true
            },
            per_page: -1
        }
    })
        .then(res =>
            res.data.data.map(d => {
                return {...d, label: d.name, value: d.id};
            })
        );

const forEmployee = params =>
    api.get(DOCUMENT_CATEGORIES_BASE_URL, {
        params: {
            filter: {
                is_for_employee: true
            },
            per_page: -1
        }
    })
        .then(res =>
            res.data.data.map(d => {
                return {...d, label: d.name, value: d.id};
            })
        );


const documentCategoryService = {
    fetchAll,
    forCompany,
    forEmployee,
    fetch,
    store,
    update,
    destroy,
};

export default documentCategoryService;
