import * as Yup from "yup";


const statuses = [
    {value: 'draft', label: 'Bozza'},
    {value: 'active', label: 'Attivo'},
    {value: 'archivied', label: 'Archiviato'},
]

const contractTypes = [
    {value: 'full_time', label: 'Tempo pieno'},
    {value: 'part_time', label: 'Part time'},
    {value: 'apprenticeship', label: 'Apprendistato'},
    {value: 'fixed_term', label: 'Determinato'},
    {value: 'open_ended', label: 'Indeterminato'},
    {value: 'project', label: 'Progetto'},
    {value: 'collaboration', label: 'Collaborazione'},
    {value: 'freelance', label: 'Freelance'},
    {value: 'other', label: 'Altro'},
]

const emptyAddress = {
    address: "",
    street_number: "",
    city: "",
    postal_code: "",
    country: "",
    latitude: "",
    longitude: "",
}

const initialValues = {
    iban: "",
    residence_address: emptyAddress,
    domicile_address_like_residence: true,
    domicile_address: emptyAddress,
    hire_date: "",
    contract_type: "",
    contract_end_date: "",
    termination_date: "",
    extension_count: "",
    renewal_count: "",
    applied_ccnl: "",
    level: "",
    notes: "",
    public_notes: "",
    status: statuses[0],
};

const validationSchema = Yup.object().shape({
    iban: Yup.string().max(100).nullable(),
    residence_address: Yup.object().shape({
        address: Yup.string().max(100).nullable(),
        street_number: Yup.string().max(20).nullable(),
        city: Yup.string().max(100).nullable(),
        postal_code: Yup.string().max(10).nullable(),
        country: Yup.string().max(100).nullable(),
    }),
    domicile_address_like_residence: Yup.boolean().required(),
    domicile_address: Yup.object()
        .when('domicile_address_like_residence', {
            is: false,
            then: Yup.object().shape({
                address: Yup.string().max(100).nullable().required(),
                street_number: Yup.string().max(20).nullable().required(),
                city: Yup.string().max(100).nullable().required(),
                postal_code: Yup.string().max(10).nullable().required(),
                country: Yup.string().max(100).nullable().required(),
            }),
        }),
    hire_date: Yup.date().nullable().required(),
    contract_type: Yup.object().nullable().required(),
    contract_end_date: Yup.date().nullable(),
    termination_date: Yup.date().nullable(),
    extension_count: Yup.number().nullable(),
    renewal_count: Yup.number().nullable(),
    applied_ccnl: Yup.string().max(100).nullable().required(),
    level: Yup.string().max(100).nullable().required(),
    notes: Yup.string().max(100).nullable(),
    public_notes: Yup.string().max(100).nullable(),
    status: Yup.object().nullable().required(),
});

const setInitialValues = mappedAttachment => {
    return {
        ...mappedAttachment,
        status: statuses.find(status => status.value === mappedAttachment.status.value),
        contract_type: contractTypes.find(contractType => contractType.value === mappedAttachment.contract_type),
        domicile_address_like_residence: !mappedAttachment.domicile_address,
        residence_address: mappedAttachment.residence_address || emptyAddress,
        domicile_address: mappedAttachment.domicile_address || emptyAddress,
        hire_date: mappedAttachment.hire_date ? new Date(mappedAttachment.hire_date) : null,
        contract_end_date: mappedAttachment.contract_end_date ? new Date(mappedAttachment.contract_end_date) : null,
        termination_date: mappedAttachment.termination_date ? new Date(mappedAttachment.termination_date) : null,
    };
};

const companyEmploymentFormConfig = {
    initialValues,
    validationSchema,
    statuses,
    contractTypes,
    setInitialValues
};

export default companyEmploymentFormConfig;
