import MenuContent from "components/layout/ProtectedLayout/_components/MenuContent/MenuContent";
import Logo from "components/layout/ProtectedLayout/_components/SideNav/Logo";
import {ScrollBar} from "components/ui";
import navigationConfig from "configs/navigation.config/navigation.config";
import {
    LOGO_X_GUTTER,
    NAV_MODE_DARK,
    NAV_MODE_THEMED,
    NAV_MODE_TRANSPARENT,
    SIDE_NAV_COLLAPSED_WIDTH,
    SIDE_NAV_CONTENT_GUTTER,
    SIDE_NAV_WIDTH,
} from "constants/theme.constant";
import React, {useContext} from "react";
import useResponsive from "utils/hooks/useResponsive";

import {ApplicationContext} from "../../../../../App";
import {cn} from "../../../../../utils/classHelpers";

const sideNavStyle = {
    width: SIDE_NAV_WIDTH,
    minWidth: SIDE_NAV_WIDTH,
};

const sideNavCollapseStyle = {
    width: SIDE_NAV_COLLAPSED_WIDTH,
    minWidth: SIDE_NAV_COLLAPSED_WIDTH,
};

const SideNav = () => {
    const {themeConfig, currentRouteKey, sideNavCollapse} = useContext(ApplicationContext);
    const {themeColor, primaryColorLevel, navMode, mode, direction} = themeConfig;
    const {larger} = useResponsive();

    const sideNavColor = () => {
        if (navMode === NAV_MODE_THEMED) {
            return `bg-${themeColor}-${primaryColorLevel} side-nav-${navMode}`;
        }
        return `side-nav-${navMode}`;
    };

    const logoMode = () => {
        if (navMode === NAV_MODE_THEMED) {
            return NAV_MODE_DARK;
        }

        if (navMode === NAV_MODE_TRANSPARENT) {
            return mode;
        }

        return navMode;
    };

    const menuContent = <MenuContent navMode={navMode} collapsed={sideNavCollapse}
                                     navigationTree={navigationConfig} routeKey={currentRouteKey}/>;

    return (
        <>
            {larger.md && (
                <div
                    style={sideNavCollapse ? sideNavCollapseStyle : sideNavStyle}
                    className={cn("side-nav", sideNavColor(), !sideNavCollapse && "side-nav-expand")}
                >
                    <div className='side-nav-header'>
                        <Logo
                            mode={logoMode()}
                            type={sideNavCollapse ? "streamline" : "full"}
                            gutter={sideNavCollapse ? SIDE_NAV_CONTENT_GUTTER : LOGO_X_GUTTER}
                        />
                    </div>
                    {sideNavCollapse ? (
                        menuContent
                    ) : (
                        <div className='side-nav-content'>
                            <ScrollBar autoHide direction={direction}>
                                {menuContent}
                            </ScrollBar>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default SideNav;
