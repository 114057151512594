import Admin from "../../../views/protected/admin/Admin";
import AdminDashboard from "../../../views/protected/admin/dashboard/AdminDashboard";
import attachmentsRoutes from "./routes/attachmentsRoutes";
import communicationsRoutes from "./routes/communicationsRoutes";
import companiesRoutes from "./routes/companies/companiesRoutes";
import companyGroupsRoutes from "./routes/companyGroupsRoutes";
import documentCategoriesRoutes from "./routes/documentCategoriesRoutes";
import documentTypesRoutes from "./routes/documentTypesRoutes";
import uploadsRoutes from "./routes/uploadsRoutes";
import usersRoutes from "./routes/usersRoutes";
import employeeDocumentTypesRoutes from "./routes/employeeDocumentTypesRoutes";

export const adminRoutes = {
    element: <Admin/>,
    children: [
        {path: "dashboard", index: true, element: <AdminDashboard/>},
        {path: "attachments", ...attachmentsRoutes},
        {path: "companies", ...companiesRoutes},
        {path: "company-groups", ...companyGroupsRoutes},
        {path: "uploads", ...uploadsRoutes},
        {path: "document-types", ...documentTypesRoutes},
        {path: "employee-document-types", ...employeeDocumentTypesRoutes},
        {path: "document-categories", ...documentCategoriesRoutes},
        {path: "users", ...usersRoutes},
        {path: "communications", ...communicationsRoutes},
    ],
};
