import { FormItem } from "components/ui/index";
import { Field, getIn, useFormikContext } from "formik";
import React from "react";

import Skeleton from "../../Skeleton";
import {cn} from "../../../../utils/classHelpers";

const FormRow = ({ label, children, name, component, required = false, loading = false, ...props }) => {
    const { errors, touched } = useFormikContext();

    return (
        <div className={cn("grid md:grid-cols-3 gap-4 py-8 items-center border-b border-gray-200 dark:border-gray-600")}>
            <div className='font-semibold'>
                {loading ? <Skeleton width='50%' height=' 1.25rem' /> : label} {required && <span className='text-red-500 ltr:mr-1 rtl:ml-1'>*</span>}
            </div>
            <div className='col-span-2'>
                {loading ? (
                    <Skeleton width='80%' height='3em' />
                ) : (
                    <FormItem
                        className='mb-0 max-w-[700px]'
                        invalid={getIn(errors, name) && getIn(touched, name)}
                        errorMessage={getIn(errors, name)}
                        extra={props?.extra}
                        extraBottom={props?.extraBottom}
                    >
                        <Field autoComplete='off' name={name} component={component} {...props}>
                            {children}
                        </Field>
                    </FormItem>
                )}
            </div>
        </div>
    );
};

export default FormRow;
