import {useRequest} from "ahooks";
import {Form, Formik} from "formik";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import * as Yup from "yup";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import {Card, Notification, toast} from "../../../../components/ui";
import CancelButton from "../../../../components/ui/Buttons/_components/CancelButton";
import DestroyButton from "../../../../components/ui/Buttons/_components/DestroyButton";
import SaveButton from "../../../../components/ui/Buttons/_components/SaveButton";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import documentTypeService from "../../../../services/documentTypeService";
import DocumentTypeForm from "./_components/DocumentTypeForm";

const DocumentTypeShow = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const [documentType, setDocumentType] = useState(undefined);
    const {runAsync} = useRequest(documentTypeService.fetch, {manual: true, defaultParams: [id]});

    const handleRun = () => {
        runAsync(id).then(res => {
                setDocumentType({
                    ...res,
                    document_category_id: {
                        value: res.document_category.id,
                        label: res.document_category.name,
                    },
                    destination_employee_document_type_id: res.destination_employee_document_type && {
                        value: res.destination_employee_document_type.id,
                        label: res.destination_employee_document_type.name,
                    },
                });
            }
        );
    };

    useEffect(() => handleRun(), []);

    const validationSchema = Yup.object().shape({
        name: Yup.string().max(255).required(),
        description: Yup.string().max(800).notRequired(),
        document_category_id: Yup.object().required(),
        is_expiration_required: Yup.bool().notRequired(),
        can_be_uploaded_by_company: Yup.bool().notRequired(),
        is_regex_enabled: Yup.bool().required(),
        is_visible_only_to_sysadmin: Yup.bool().required(),
        filename_regex: Yup.string(50)
            .nullable()
            .notRequired()
            .when("is_regex_enabled", {
                is: true,
                then: Yup.string(50).nullable().required(),
            }),
    });

    const initialValues = {
        name: documentType?.name,
        description: documentType?.description,
        document_category_id: documentType?.document_category_id,
        is_expiration_required: documentType?.is_expiration_required,
        can_be_uploaded_by_company: documentType?.can_be_uploaded_by_company,
        is_regex_enabled: !!documentType?.filename_regex,
        filename_regex: documentType?.filename_regex,
        is_visible_only_to_sysadmin: documentType?.is_visible_only_to_sysadmin,
        destination_employee_document_type_id: documentType?.destination_employee_document_type_id,
    };

    const handleDelete = () => {
        documentTypeService
            .destroy(id)
            .then(res => {
                toast.push(<Notification title='Tipo documenti aziendale eliminato con successo'
                                         type='success'/>);
                navigate("../");
            })
            .catch(err => toast.push(<Notification title={err?.response?.data?.message}
                                                   type='danger'/>));
    };

    return (
        <PageContainer>
            {documentType && (
                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={(values, {setSubmitting}) => {
                        console.log(values);
                        documentTypeService
                            .update(documentType.uuid, {
                                ...values,
                                filename_regex: values.is_regex_enabled ? values.filename_regex : null,
                                document_category_id: values.document_category_id.value,
                                destination_employee_document_type_id: values.destination_employee_document_type_id.value,
                            })
                            .then(res => {
                                toast.push(<Notification title='Tipo aggiornato con successo'
                                                         type='success'/>);
                                setDocumentType({
                                    ...res,
                                    document_category_id: {
                                        value: res.document_category.id,
                                        label: res.document_category.name,
                                    },
                                    destination_employee_document_type_id: res.destination_employee_document_type && {
                                        value: res.destination_employee_document_type.id,
                                        label: res.destination_employee_document_type.name,
                                    },
                                });
                            })
                            .catch(err => toast.push(<Notification
                                title={err?.response?.data?.message} type='danger'/>))
                            .finally(() => setSubmitting(false));
                    }}
                >
                    {({isValid, isSubmitting, values, resetForm}) => (
                        <Form>
                            <Card
                                header={
                                    <PageHeader
                                        title={`Modifica ${documentType.name}`}
                                        loading={false}
                                        toolbar={
                                            <>
                                                <CancelButton label={"Annulla"}
                                                              handleClick={() => navigate("../")}/>
                                                <SaveButton loading={isSubmitting}
                                                            disabled={!isValid}/>
                                                <DestroyButton
                                                    message={`Sei sicuro di voler eliminare la tipologia documento ${values?.name}?`}
                                                    handleClick={handleDelete}
                                                />
                                            </>
                                        }
                                    />
                                }
                            >
                                <div className='flex flex-col gap-4'>
                                    <DocumentTypeForm/>
                                </div>
                            </Card>
                        </Form>
                    )}
                </Formik>
            )}
        </PageContainer>
    );
};

export default DocumentTypeShow;
