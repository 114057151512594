import api from "../configs/api.config";

const EMPLOYEES_BASE_URL = "/employees";

const fetchAll = params => api.get(EMPLOYEES_BASE_URL, {params}).then(res => res.data);
const fetch = id => api.get(`${EMPLOYEES_BASE_URL}/${id}`).then(res => res.data.data);
const store = params => api.post(`${EMPLOYEES_BASE_URL}`, params).then(res => res.data.data);
const update = (id, params) => api.put(`${EMPLOYEES_BASE_URL}/${id}`, params).then(res => res.data.data);

const findByFiscalCode = fiscal_code => api.post(`${EMPLOYEES_BASE_URL}/find-fiscal-code`, {fiscal_code}).then(res => res.data.data);


const employeesService = {
    fetchAll,
    fetch,
    store,
    update,
    findByFiscalCode,
};

export default employeesService;
