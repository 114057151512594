import React, {useContext} from "react";

import DocumentNavigator
    from "../../../../../../../components/ui/DocumentNavigator/DocumentNavigator";
import {CompanyContext} from "../../Company";
import {Card} from "../../../../../../../components/ui";

const CompanyDocumentsTab = () => {
    const {company} = useContext(CompanyContext);

    return <Card>
        <DocumentNavigator company={company} isAdmin={true}/>
    </Card>;
};

export default CompanyDocumentsTab;
