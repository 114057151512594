import {cn} from "../../../../utils/classHelpers";
import React from "react";

import Skeleton from "../../Skeleton";

const FormRowDisplay = ({ label, children, required = false, loading = false, ...props }) => {
    return (
        <div className={cn("grid md:grid-cols-3 gap-4 py-8 border-b border-gray-200 dark:border-gray-600")}>
            <div className='font-semibold'>
                {loading ? <Skeleton width='50%' height=' 1.25rem' /> : label} {required && <span className='text-red-500 ltr:mr-1 rtl:ml-1'>*</span>}
            </div>
            <div className='col-span-2'>{loading ? <Skeleton width='80%' height='3em' /> : <div className='mb-0 max-w-[700px]'>{children}</div>}</div>
        </div>
    );
};

export default FormRowDisplay;
