import {useRequest, useUpdateEffect} from "ahooks";
import {useFormikContext} from "formik";
import React, {useEffect} from "react";

import FormCheckbox from "../../../../../components/RefactoredForms/FormCheckbox";
import FormDescription from "../../../../../components/RefactoredForms/FormDescription";
import FormGroup from "../../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../../components/RefactoredForms/FormInput";
import FormSelect from "../../../../../components/RefactoredForms/FormSelect";
import documentCategoryService from "../../../../../services/documentCategoryService";
import listService from "../../../../../services/listService";

const DocumentTypeForm = () => {
    const {setFieldTouched, values, setValues, setFieldValue} = useFormikContext();
    const {data: documentCategories} = useRequest(documentCategoryService.forCompany);
    const {data: employeeDocumentTypes, run} = useRequest(listService.employeeDocumentTypes, {
        manual: true,
    });

    useUpdateEffect(() => {
        setFieldValue('destination_employee_document_type_id', null);
    }, [values.document_category_id]);

    useEffect(() => {
        if (values.document_category_id) {
            run({filter: {document_category_id: values.document_category_id.id}});
        }
    }, [values.document_category_id]);

    return (
        <div className='flex flex-col gap-4'>
            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                <FormGroup label='Nome' name='name' required>
                    <FormInput name='name'/>
                </FormGroup>
                <FormGroup label='Descrizione' name='description' required>
                    <FormInput name='description'/>
                </FormGroup>
                <FormGroup label='Categoria documento' name={"document_category_id"}>
                    <FormSelect
                        isClearable
                        placeholder='Seleziona una categoria'
                        value={values.document_category_id}
                        name='document_category_id'
                        onBlur={() => setFieldTouched("document_category_id")}
                        options={documentCategories}
                        onChange={value =>
                            setValues({
                                ...values,
                                document_category_id: value,
                            })
                        }
                    />
                </FormGroup>
                <FormGroup label='' name='can_be_uploaded_by_company'>
                    <FormCheckbox name='can_be_uploaded_by_company'
                                  label={"L'azienda può caricare questa tipologia di documento."}/>
                </FormGroup>
                <FormGroup label='' name='is_visible_only_to_sysadmin'>
                    <FormCheckbox name='is_visible_only_to_sysadmin'
                                  label={"Questa tipologia di documento è visibile solo a Studio Barzaghi."}/>
                </FormGroup>
            </div>
            <FormDescription
                title='Riconoscimento automatico file'
                desc="Per riconoscere in automatico il file è necessario specificare la parte iniziale del nome del file fino al primo '_' (underscore).
                                    Tutti i file che inizieranno con il prefisso specificato saranno automaticamente riconosciuti come dello stessa tipologia."
            />

            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                <FormGroup label='' name='is_regex_enabled'>
                    <FormCheckbox name='is_regex_enabled'
                                  label={"Abilita riconoscimento automatico"}/>
                </FormGroup>
                <FormGroup
                    label='Nome allegato'
                    name='filename_regex'
                    required={values.is_regex_enabled}
                    info={values.is_regex_enabled ? `Es. ${values.filename_regex}_nomefile` : null}
                >
                    <FormInput name='filename_regex' disabled={!values.is_regex_enabled}/>
                </FormGroup>
            </div>

            <FormDescription title='Scadenza'
                             desc='Abilita la richiesta di inserimento della data di scadenza per questa tipologia di documento.'/>
            <FormGroup label='' name='is_expiration_required'>
                <FormCheckbox name='is_expiration_required' label={"Abilita"}/>
            </FormGroup>


            <FormDescription title='Suddivisone'
                             desc="Abilita la suddivisione automatica del documento per le aziende che hanno attiva l'opzione"/>
            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                <FormGroup label='' name='destination_employee_document_type_id'>
                    <FormSelect
                        disabled={!employeeDocumentTypes || employeeDocumentTypes.length === 0}
                        isClearable
                        placeholder='Seleziona una documento dipendente di destinazione'
                        value={values.destination_employee_document_type_id}
                        name='destination_employee_document_type_id'
                        onBlur={() => setFieldTouched("destination_employee_document_type_id")}
                        options={employeeDocumentTypes}
                    />
                </FormGroup>
            </div>
        </div>
    );
};

export default DocumentTypeForm;
