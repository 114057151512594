import { useFormikContext } from "formik";
import { get } from "lodash";
import React from "react";

const FormTextarea = ({ name, ...props }) => {
    const { values, setFieldValue, setFieldTouched, errors } = useFormikContext();

    return (
        <textarea
            name={name}
            onChange={e => setFieldValue(name, e.target.value)}
            onBlur={() => setFieldTouched(name)}
            value={get(values, name)}
            rows={10}
            className={`input input-md focus:ring-sky-800 focus-within:ring-sky-800 focus-within:border-sky-800 focus:border-sky-800 text-gray-700 dark:text-gray-200 font-semibold ${
                get(errors, name) && "input-invalid"
            }`}
            {...props}
        >
            {get(values, name)}
        </textarea>
    );
};

export default FormTextarea;
