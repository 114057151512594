import React from "react";
import {cn} from "../classHelpers";

const withHeaderItem = Component => props => {
    const {className, hoverable = true} = props;

    return <Component {...props}
                      className={cn("header-action-item", hoverable && "header-action-item-hoverable", className)}/>;
};

export default withHeaderItem;
