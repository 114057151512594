import {useRequest} from "ahooks";
import {useFormikContext} from "formik";
import React from "react";

import FormCheckbox from "../../../../../components/RefactoredForms/FormCheckbox";
import FormDescription from "../../../../../components/RefactoredForms/FormDescription";
import FormGroup from "../../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../../components/RefactoredForms/FormInput";
import FormSelect from "../../../../../components/RefactoredForms/FormSelect";
import documentCategoryService from "../../../../../services/documentCategoryService";

const EmployeeDocumentTypeForm = () => {
    const {setFieldTouched, values, setValues} = useFormikContext();
    const {data: documentCategories} = useRequest(documentCategoryService.forEmployee);

    return (
        <div className='flex flex-col gap-4'>
            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                <FormGroup label='Nome' name='name' required>
                    <FormInput name='name'/>
                </FormGroup>
                <FormGroup label='Descrizione' name='description' required>
                    <FormInput name='description'/>
                </FormGroup>
                <FormGroup label='Categoria documento' name={"document_category_id"}>
                    <FormSelect
                        isClearable
                        placeholder='Seleziona una categoria'
                        value={values.document_category_id}
                        name='document_category_id'
                        onBlur={() => setFieldTouched("document_category_id")}
                        options={documentCategories}
                        onChange={value =>
                            setValues({
                                ...values,
                                document_category_id: value,
                            })
                        }
                    />
                </FormGroup>
                <FormGroup label='' name='can_be_uploaded_by_company'>
                    <FormCheckbox name='can_be_uploaded_by_company'
                                  label={"L'azienda può caricare questa tipologia di documento."}/>
                </FormGroup>
                <FormGroup label='' name='is_visible_only_to_sysadmin'>
                    <FormCheckbox name='is_visible_only_to_sysadmin'
                                  label={"Questa tipologia di documento è visibile solo a Studio Barzaghi."}/>
                </FormGroup>
            </div>

            <FormDescription title='Scadenza'
                             desc='Abilita la richiesta di inserimento della data di scadenza per questa tipologia di documento.'/>
            <FormGroup label='' name='is_expiration_required'>
                <FormCheckbox name='is_expiration_required' label={"Abilita"}/>
            </FormGroup>
        </div>
    );
};

export default EmployeeDocumentTypeForm;
