import {HEADER_HEIGHT_CLASS} from "constants/theme.constant";
import React from "react";

import MobileNav from "../SideNav/MobileNav";
import CompanySwitcher from "./_components/CompanySwitcher";
import {SideNavToggle} from "./_components/SideNavToggle";
import {UserDropdown} from "./_components/UserDropdown";
import {cn} from "../../../../../utils/classHelpers";

const Header = ({container}) => {
    return (
        <header className='header shadow dark:shadow-2xl'>
            <div
                className={cn("header-wrapper", HEADER_HEIGHT_CLASS, container && "container mx-auto")}>
                <div className='header-action header-action-start'>
                    <MobileNav/>
                    <SideNavToggle/>
                </div>
                <div className='header-action header-action-end gap-4'>
                    <CompanySwitcher/>
                    <UserDropdown/>
                </div>
            </div>
        </header>
    );
};

export default Header;
