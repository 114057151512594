import React, {useContext} from "react";

import FormDescription from "../../../../../../../components/RefactoredForms/FormDescription";
import FormDisplay from "../../../../../../../components/RefactoredForms/FormDisplay";
import {CompanyContext} from "../../Company";
import {Card} from "../../../../../../../components/ui";

const CompanySupervisedTab = () => {
    const {company, loading} = useContext(CompanyContext);


    if (loading) {
        return <></>;
    }

    if (company.supervised_companies.length === 0) {
        return <Card>Nessuna azienda supervisionata</Card>;
    }


    return company.supervised_companies.map(supervised => (
        <Card>
            <FormDescription
                key={supervised.id}
                title={supervised?.business_name}
                desc={
                    <div className='grid xl:grid-cols-4 lg:grid-cols-2 gap-4'>
                        <FormDisplay value='Codice'>{supervised?.code}</FormDisplay>
                        <FormDisplay value='Partita Iva'>{supervised?.vat_number}</FormDisplay>
                        <FormDisplay value='Codice Fiscale'>{supervised?.fiscal_code}</FormDisplay>
                    </div>
                }
            />
        </Card>
    ))
};

export default CompanySupervisedTab;
