import {cn} from "../../../../utils/classHelpers";
import React from "react";

import { CONTROL_SIZES, LAYOUT } from "../../utils/constant";
import { useForm } from "../context";

const FormDisplay = ({ label, children, layout }) => {
    const formContext = useForm();
    const formItemLayout = layout || formContext.layout;

    const formItemClass = cn("form-item", formItemLayout);

    return (
        <div className={formItemClass}>
            <span className='form-label mb-2'>{label}</span>
            <span className={formItemLayout === LAYOUT.HORIZONTAL ? "w-full flex flex-col justify-center relative" : "flex"}>{children}</span>
        </div>
    );
};

export default FormDisplay;
